<template>
  <view-wrapper v-if="!inStoock" :title="`订单号：${form.data.serialNo}`">
    <template #operation>
      <a-button v-if="form.data.status.code === 'C' && isShowTolibrary" @click="form.tolibrary.click" type="primary">
        去入库
      </a-button>
    </template>
    <template #header>
      <a-form class="form-info" style="position: relative">
        <img v-if="form.data.status.code === 'R'" class="cancel-icon" :src="CancelImg" alt="" />
        <a-row>
          <a-col :span="7">
            <a-form-item label="公司名称：">
              <span>{{ form.data.supplier.name || form.data.supplier.shortName }}</span>
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item label="支付方式：">
              <span>{{ form.data.paymentMethod || '---' }}</span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="7">
            <a-form-item label="收件人：">
              <span>{{ form.data.inquiry.addressContactName }}</span>
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item label="备注：">
              <span>{{ form.data.inquiry.comment }}</span>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="7">
            <a-form-item label="联系方式:">
              <span>{{ form.data.inquiry.addressCellphone }}</span>
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item label="附件：">
              <FileImageOutlined style="width: 11px; height: 14px; color: #0d8d8d" @click="form.preview.click" />
              <span style="margin-left: 8px"
                >({{ form.data.inquiry.inquiryImages.length ? form.data.inquiry.inquiryImages.length : 0 }}张)</span
              >
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="7">
            <a-form-item label="收货地址:">
              <span>{{ address }}</span>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </template>
    <div style="padding: 0 24px 24px 24px; background: #fff">
      <a-form class="form-info" :loading="form.loading">
        <div class="title-text">配件清单</div>
        <a-table :pagination="false" rowKey="id" :data-source="form.data.purchaseGoods" :columns="columnsOne">
          <template #status="{ record }">
            <a-badge v-if="record.status.code === 'P'" status="error" text="待发货" />
            <a-badge v-if="record.status.code === 'C'" status="warning" text="已发货" />
            <a-badge v-if="record.status.code === 'A'" status="success" text="已入库" />
          </template>
        </a-table>
        <div class="flex items-center justify-end mt-[20px]">
          <span class="text-[16px]">
            <span>金额总计</span>
            <span class="font-bold">{{ formatMoney(totalAmount, '￥') }}</span>
          </span>
        </div>
        <div class="title-text">订单流程</div>
        <div>
          <div class="steps-header">
            <ul class="steps__tr">
              <li class="steps__th">处理时间</li>
              <li class="steps__th">处理信息</li>
              <li class="steps__th">操作人</li>
            </ul>
          </div>
          <div class="steps-content">
            <ul class="steps__tr" v-for="item in form.data.documentLogs" :key="item.id">
              <li class="steps__td">
                <div>
                  <span class="steps-icon-dot"></span>
                  <span>{{ formatDate(item.datetime, true) }}</span>
                </div>
                <div class="steps-icon-line"></div>
              </li>
              <li class="steps__td">{{ item.comment }}</li>
              <li class="steps__td">{{ item.operatorName }}</li>
            </ul>
          </div>
        </div>
        <div class="title-text">发票信息</div>
        <div v-if="form.data.inquiry.invoiceType && form.data.inquiry.invoiceType.code !== 'N'">
          <a-row>
            <a-col :span="7">
              <a-form-item label="发票抬头：">
                <span>{{ form.data.inquiry.invoiceTitle }}</span>
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item label="纳税人识别号：">
                <span>{{ form.data.inquiry.invoiceTaxNo }}</span>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="7">
              <a-form-item label="公司电话：">
                <span>{{ form.data.inquiry.invoiceCompanyPhone }}</span>
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item label="公司地址：">
                <span>{{ form.data.inquiry.invoiceCompanyAddress }}</span>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="7">
              <a-form-item label="开户行：">
                <span>{{ form.data.inquiry.invoiceBankName }}</span>
              </a-form-item>
            </a-col>
            <a-col :span="7">
              <a-form-item label="银行账号：">
                <span>{{ form.data.inquiry.invoiceBankAccount }}</span>
              </a-form-item>
            </a-col>
          </a-row>
        </div>
        <p v-else>不需要发票</p>
      </a-form>
    </div>
  </view-wrapper>
  <view-wrapper v-else title="入库">
    <div style="padding: 0 24px 24px 24px; background: #fff">
      <div class="title-text">入库概括</div>
      <a-form v-model="stockIn.data" class="myform">
        <a-row>
          <a-col :span="7">
            <a-form-item label="供应商:">
              <a-input disabled :value="stockIn.data.supplier.name" />
              <span class="ml-[10px]"> <FormOutlined class="cursor-pointer" @click="supplierMoalOpenClick" /></span>
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item label="仓库:" :rules="{ required: true }">
              <e-select
                placeholder="请选择"
                :ajax="{ get: { action: 'GET /client/common/warehouse' } }"
                :allowClear="false"
                v-model="stockIn.data.warehouse"
                :props="{ label: 'name', value: '' }"
                value-key="id"
              ></e-select>
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item label="税率%：">
              <a-input-number
                placeholder="请输入"
                v-model:value="stockIn.data.taxRate"
                :min="0"
                :max="100"
                :precision="2"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="7">
            <a-form-item label="运费支付￥:">
              <e-select
                :ajax="{ get: { action: 'GET /common/lookup/FRPMMTD' } }"
                placeholder="请选择"
                v-model="stockIn.data.freightPaymentMethod"
                :props="{ label: 'message', value: '' }"
                value-key="code"
                @change="freightPaymentMethodChange"
              ></e-select>
            </a-form-item>
          </a-col>
          <a-col :span="7">
            <a-form-item label="物流费用:">
              <a-input-number
                :disabled="stockIn.data.freightPaymentMethod.code === 'N'"
                v-model:value="stockIn.data.freight"
                placeholder="请输入"
                :precision="2"
                :min="0"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div class="title-text flex items-center justify-between">
        <span>入库详情</span>
        <a-button @click="addGoodsclick"> 添加配件 </a-button>
      </div>
      <a-table
        :pagination="false"
        rowKey="id"
        :row-selection="{ onChange: form.selectedRows.change }"
        :data-source="stockIn.data.filterGoods"
        :columns="columnsTwo"
      >
        <template #enocloudSerialNo="{ record }">
          <span v-if="record.manuallyAdd">{{ record.purchaseGoods.enocloudGoodsSpecification.goods.serialNo }}</span>
          <span v-else-if="record.purchaseGoods.status.code === 'A'">{{
            record.purchaseGoods.enocloudGoodsSpecification.goods.serialNo
          }}</span>
          <span v-else-if="record.enocloudGoods">{{ record.enocloudGoods.serialNo }}</span>
          <span v-else>
            <a-input v-model:value="record.purchaseGoods.enocloudGoodsSpecification.goods.serialNo"></a-input>
          </span>
        </template>
        <template #enocloudOem="{ record }">
          <span v-if="record.manuallyAdd">{{ record.purchaseGoods.enocloudGoodsSpecification.goods.oem }}</span>
          <span v-else-if="record.purchaseGoods.status.code === 'A'">{{
            record.purchaseGoods.enocloudGoodsSpecification.goods.oem
          }}</span>
          <span v-else-if="record.enocloudGoods">{{ record.enocloudGoods.oem }}</span>
          <span v-else>
            <a-input v-model:value="record.purchaseGoods.enocloudGoodsSpecification.goods.oem"></a-input>
          </span>
        </template>
        <template #price="{ text, record }">
          <div class="switch-block">
            <span class="switch-block__text">{{ formatMoney(text, '￥') }}</span>
            <a-input-number
              class="switch-block__input"
              v-model:value="record.price"
              @change="servicePriceUpdate(record)"
              placeholder="请输入"
              :min="0"
              :precision="2"
            />
          </div>
        </template>
        <template #count="{ text, record }">
          <div class="switch-block w-full">
            <span class="switch-block__text">{{ text }}</span>
            <e-input-number :max="record.maxCount" class="switch-block__input" v-model="record.count"></e-input-number>
          </div>
        </template>
        <template #specification="{ record }">
          <span v-if="record.manuallyAdd">{{ record.purchaseGoods.enocloudGoodsSpecification.name }}</span>
          <div v-else-if="record.purchaseGoods.status.code === 'A'">
            {{ record.purchaseGoods.enocloudGoodsSpecification.name }}
          </div>
          <div v-else-if="record.enocloudGoods" class="w-full">
            <a-select
              class="w-full"
              :allowClear="false"
              placeholder="请选择"
              v-model:value="record.purchaseGoods.enocloudGoodsSpecification.id"
            >
              <a-select-option :value="item.id" v-for="item in record.enocloudGoods.specifications" :key="item.id"
                >{{ item.name }}
              </a-select-option>
            </a-select>
          </div>
          <div v-else>
            <e-select
              class="w-full"
              :ajax="{ get: { action: 'GET /client/common/hint/GDSUNT' } }"
              :allowClear="false"
              placeholder="请选择"
              v-model="record.purchaseGoods.enocloudGoodsSpecification.name"
              :props="{ label: 'name', value: 'name' }"
              value-key="id"
            ></e-select>
          </div>
        </template>
        <template #servicePrice="{ record }">
          <span class="flex items-center">
            <span>￥</span>
            <a-input-number v-model:value="record.servicePrice" :min="0" :precision="2" />
          </span>
        </template>
        <template #warehouseShelf="{ record }">
          <a-input :maxlength="12" placeholder="请输入" v-model:value="record.warehouseShelf"></a-input>
        </template>
        <template #operation="{ record, index }">
          <a v-if="record.manuallyAdd" class="text-primary operation-model" @click="deleteGoodsClick(index)">删除</a>
        </template>
      </a-table>
      <div class="flex justify-end" style="margin-top: 24px; align-items: flex-end">
        <span>{{ `本次入库 ${goodsSummary.size} 类配件，数量共 ${goodsSummary.count}个 ` }}</span
        ><span style="margin: 0 24px 0 20px"
          >总价：<span class="font-weight">{{ formatMoney(goodsSummary.totalAmount, '￥') }}</span></span
        >
        <a-button
          type="primary"
          :disabled="!goodsSummary.count"
          @click="stockIn.submit.click"
          :loading="stockIn.submit.laodding"
          >确 定</a-button
        >
      </div>
    </div>
  </view-wrapper>
  <a-modal v-model:visible="imgPreview.visible" :footer="null" @cancel="imgPreview.visible = false">
    <div class="mt-20px">
      <img alt="image" style="width: 100%" :src="imgPreview.imgUrl" />
    </div>
  </a-modal>
  <a-modal v-model:visible="supplierMoal" @ok="supplierMoalOkClick" centered title="编辑供应商" width="900px">
    <div class="mt-20px">
      <a-form :model="supplierData">
        <a-row>
          <a-col :span="10">
            <a-form-item label="供应商:" :rules="{ required: true }">
              <e-select
                :ajax="{ get: { action: 'GET /client/common/customer', params: (val:string) => ({ name: val }) } }"
                placeholder="请选择"
                allowCreate
                v-model="supplierData.name"
                :props="{ label: 'name', value: 'name' }"
                value-key="id"
                @change="supplierChange"
              ></e-select>
            </a-form-item>
          </a-col>
          <a-col :span="10" offset="2">
            <a-form-item label="联系电话:" :rules="{ required: true }">
              <a-input :disabled="!!supplierData.id" v-model:value="supplierData.cellphone"></a-input>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
  </a-modal>
  <a-drawer title="添加配件" :visible="addGoodsDrawer.visible" :width="480" @close="addGoodsDrawer.visible = false">
    <a-form :model="addGoodsDrawer.form.data" layout="vertical">
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item label="配件名称" :rules="{ required: true }">
            <e-select
              placeholder="请选择或输入"
              :ajax="{ get: { action: 'GET /client/common/goods', params: (val:string) => ({ quickSearch: val }) } }"
              allowCreate
              v-model="addGoodsDrawer.form.data.goods.name"
              :props="{ label: 'name', value: 'name' }"
              @change="goodsChange"
              value-key="name"
            ></e-select>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="规格" :rules="{ required: true }">
            <template v-if="addGoodsDrawer.form.data.goods.id">
              <a-select
                class="w-full"
                :allowClear="false"
                placeholder="请选择"
                v-model:value="addGoodsDrawer.form.data.specification.id"
                @select="specificationChange"
              >
                <a-select-option :value="item.id" v-for="item in addGoodsDrawer.form.data.specifications" :key="item.id"
                  >{{ item.name }}
                </a-select-option>
              </a-select>
            </template>
            <template v-else>
              <e-select
                class="w-full"
                :ajax="{ get: { action: 'GET /client/common/hint/GDSUNT' } }"
                :allowClear="false"
                placeholder="请选择"
                v-model="addGoodsDrawer.form.data.specification.name"
                :props="{ label: 'name', value: 'name' }"
                value-key="id"
              ></e-select>
            </template>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item label="系统编码">
            <a-input
              :disabled="!!addGoodsDrawer.form.data.goods.id"
              v-model:value="addGoodsDrawer.form.data.goods.serialNo"
              placeholder="请输入"
            />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="系统OE">
            <a-input v-model:value="addGoodsDrawer.form.data.goods.oem" placeholder="请输入" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item label="单价">
            ￥
            <a-input-number :min="0" style="width: 90%" v-model:value="addGoodsDrawer.form.data.price" :precision="2" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="维修价格">
            ￥
            <a-input-number
              :min="0"
              style="width: 90%"
              v-model:value="addGoodsDrawer.form.data.servicePrice"
              :precision="2"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item label="仓位">
            <a-input placeholder="请输入" v-model:value="addGoodsDrawer.form.data.warehouseShelf"></a-input>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="入库数量" :rules="{ required: true }">
            <e-input-number v-model="addGoodsDrawer.form.data.count"></e-input-number>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <div class="drawer-footer">
      <a-button style="margin-right: 8px" @click="addGoodsDrawer.visible = false">取消</a-button>
      <a-button type="primary" @click="addGoodsDrawer.confirmClick">确定</a-button>
    </div>
  </a-drawer>
</template>
<script lang="ts" setup>
import { ref, reactive, computed, h } from 'vue'
import { useAjax, formatMoney, formatDate } from '@vue-mfe/utils'
import { useRouter, useRoute } from 'vue-router'
import { uniq, multiply, add } from 'lodash-es'

import {
  message,
  Modal as AModal,
  Drawer as ADrawer,
  Row as ARow,
  Col as ACol,
  InputNumber as AInputNumber,
  Badge as ABadge,
  Select as ASelect,
  SelectOption as ASelectOption
} from 'ant-design-vue'
import { FileImageOutlined, FormOutlined } from '@ant-design/icons-vue'

import CancelImg from '@client/assets/cancel.png'
import type { ColumnProps } from 'ant-design-vue/es/table/interface'
interface ragne {
  amount: number
  benefitType: { code: string }
  endAmount: number
  initialAmount: number
}
let inStoock = ref<Boolean>(false)
const router = useRouter()
const route = useRoute()
const id = route.params.id

const columnsTwo = [
  { title: '序号', width: 60, customRender: ({ index }) => index + 1 },
  {
    title: '配件名称',
    width: 100,
    ellipsis: true,
    customRender: ({ record }) =>
      record.manuallyAdd
        ? record.purchaseGoods.enocloudGoodsSpecification.goods.name
        : record.purchaseGoods.quotationGoods.inquiryGoods.name
  },
  { title: '配件编码', width: 90, dataIndex: 'oem' },
  { title: '系统编码', dataIndex: 'amount', slots: { customRender: 'enocloudSerialNo' } },
  { title: '系统OE', slots: { customRender: 'enocloudOem' } },
  { title: '规格', slots: { customRender: 'specification' } },
  { title: '单价', dataIndex: 'price', slots: { customRender: 'price' } },
  { title: '已入数量', width: 90, dataIndex: 'stockedInCount' },
  { title: '可入数量', dataIndex: 'count', slots: { customRender: 'count' } },
  { title: '小计', width: 120, customRender: ({ record }) => formatMoney(record.price * record.count, '￥') },
  { title: '维修价格', slots: { customRender: 'servicePrice' } },
  { title: '仓位', with: 100, slots: { customRender: 'warehouseShelf' } },
  { title: '操作', with: 80, slots: { customRender: 'operation' } }
] as ColumnProps[]
const totalAmount = computed(
  () => {
    return form.data.purchaseGoods.reduce((cur: number, next: any) => cur + next.amount, 0)
  }
)
const { data: rangeData } = useAjax<ragne>(
  {
    action: 'GET /client/common/range'
  }
)
const { loading: getDetailLoading, run: getDetail } = useAjax(
  {
    action: 'GET /client/inquiry/purchase/:purchaseId',
    params: id,
    success (data) {
      form.data = Object.assign(form.data, data[0])
      supplierName.value = data[0].supplier.name
      getDefaultSupplier()
    },
    lazy: true
  }
)
const columnsOne = computed(
  () => {
    const columns = [
      { title: '配件名称', width: 200, ellipsis: true, dataIndex: 'quotationGoods.inquiryGoods.name' },
      { title: '配件编码', dataIndex: 'quotationGoods.oem' },
      {
        title: '单价',
        dataIndex: 'price',
        customRender: ({ text }) =>
          h(
            'span',
            {
              class: ['font-bold', 'text-[16px]']
            },
            formatMoney(text, '￥')
          )
      },
      { title: '数量', dataIndex: 'count' },
      { title: '已入库数量', dataIndex: 'stockedInCount' },
      {
        title: '小计',
        dataIndex: 'amount',
        customRender: ({ text }) =>
          h(
            'span',
            {
              class: ['font-bold', 'text=[16px]']
            },
            formatMoney(text, '￥')
          )
      },
      { title: '品质类型', dataIndex: 'quotationGoods.qualityType.message' },
      {
        title: '质保周期',
        dataIndex: 'quotationGoods.warrantyMonth',
        customRender: ({ text }) => `${text || 0}个月`
      },
      {
        title: '订货周期',
        dataIndex: 'quotationGoods.reserveDays',
        customRender: ({ text }) => (!text ? '现货' : `${text}天`)
      }
    ] as ColumnProps[]
    if (form.data.status.code !== 'R') {
      const column = { title: '状态', slots: { customRender: 'status' } } as ColumnProps
      columns.push(column)
    }
    return columns
  }
)

const form = reactive(
  {
    loading: getDetailLoading,
    data: {
      serialNo: '',
      paymentMethod: '',
      supplier: { name: '', id: '', shortName: '', contactCellphone: '' },
      branchName: '',
      preparedBy: '',
      status: {
        code: ''
      },
      inquiry: {
        inquiryImages: [],
        addressDetail: '',
        addressArea: {
          code: '',
          name: '',
          parent: {
            name: '',
            parent: {
              name: ''
            }
          }
        },
        invoiceBankAccount: '',
        invoiceBankName: '',
        invoiceCompanyAddress: '',
        invoiceCompanyPhone: '',
        invoiceTaxNo: '',
        invoiceTitle: '',
        invoiceType: {
          code: ''
        }
      },
      purchaseGoods: [] as any[],
      documentLogs: [] as any[]
    } as any,
    get: getDetail,

    preview: {
      click () {
        if (!form.data.inquiry.inquiryImages.length) return false
        imgPreview.click(form.data.inquiry.inquiryImages[0])
      }
    },
    tolibrary: {
      click () {
        stockIn.init()
        const stockInGoods = form.data.purchaseGoods.filter((item: any) => ['C', 'A'].includes(item.status.code))
        // if (!stockInGoods.length) return message.warning('没有配件发货，无法入库')

        stockInGoods.forEach(
          (item: any) => {
            if (item.stockedInCount < item.count) {
              const purchaseGoods = {
                enocloudGoodsSpecification: {
                  name: '',
                  // 已有规格配件，默认第一个规格
                  id:
                    item.quotationGoods.inquiryGoods.enocloudGoods &&
                    item.quotationGoods.inquiryGoods.enocloudGoods.specifications[0]
                      ? item.quotationGoods.inquiryGoods.enocloudGoods.specifications[0].id
                      : '',
                  goods: {
                    serialNo: '',
                    oem: ''
                  }
                },
                ...item
              }
              // servicePrice =
              const goods = {
                id: item.id,
                count: item.count - item.stockedInCount,
                maxCount: item.count - item.stockedInCount,
                stockedInCount: item.stockedInCount,
                price: item.price,
                servicePrice: 0,
                warehouseShelf: '',
                oem: item.quotationGoods.oem,
                purchaseGoods,
                enocloudGoods: item.quotationGoods.inquiryGoods.enocloudGoods
              }
              servicePriceUpdate(goods)
              stockIn.data.filterGoods.push(goods)
            }
          }
        )
        if (!stockIn.data.filterGoods.length) return message.warning('配件可入库数量为零，无法入库')
        inStoock.value = true
      }
    },
    selectedRows: {
      change (selectedRowKeys: any[], selectedRows: any[]) {
        console.log(selectedRowKeys, 'selectedRowKeys')
        stockIn.data.stockInGoods = selectedRows as any[]
      }
    }
  }
)
const freightPaymentMethodChange = ({ value = '' as any }) => {
  if (value === 'N') {
    stockIn.data.freight = 0
  }
}

const stockIn = reactive(
  {
    data: {
      freight: 0 as number,
      freightPaymentMethod: {
        code: ''
      },
      supplier: {
        id: '',
        name: '',
        cellphone: ''
      },

      warehouse: {
        id: ''
      },
      taxRate: 0 as number,
      filterGoods: [] as any[],
      stockInGoods: [] as any[]
    },
    init () {
      stockIn.data.freight = 0
      stockIn.data.freightPaymentMethod = {
        code: 'N'
      }
      // stockIn.data.supplier = {
      //   id: '',
      //   name: '',
      //   cellphone: ''
      // }
      stockIn.data.warehouse = {
        id: ''
      }
      stockIn.data.taxRate = 0
      stockIn.data.stockInGoods = []
    },
    submit: {
      laodding: false,
      click () {
        if (!stockIn.data.supplier.name) {
          return message.warning('请选择供应商!')
        }
        if (!stockIn.data.warehouse.id) {
          return message.warning('请选择仓库!')
        }
        let flag = false
        stockIn.data.stockInGoods.forEach(
          (item: any) => {
            if (
              !item.purchaseGoods.enocloudGoodsSpecification.id &&
              !item.purchaseGoods.enocloudGoodsSpecification.name
            ) {
              flag = true
            }
          }
        )
        if (flag) return message.warning('请选择规格!')
        const { loading: stockInLoading, run: postStockIn } = useAjax(
          {
            action: 'POST /client/inquiry/purchase/:purchaseId/stock',
            params: () => [
              id,
              Object.assign(
                { ...stockIn.data },
                {
                  taxRate: stockIn.data.taxRate / 100
                }
              )
            ],
            success () {
              AModal.success(
                {
                  title: () => '入库成功',
                  okText: () => '采购订单',
                  centered: true,
                  onOk: () => {
                    router.push({ path: '/client/purchase/order' })
                  }
                }
              )
            },
            lazy: true
          }
        )
        stockIn.submit.laodding = stockInLoading.value
        postStockIn()
      }
    }
  }
)

const supplierName = ref('')

const { run: getDefaultSupplier } = useAjax(
  {
    action: 'GET /client/common/customer',
    params: () => ({ name: supplierName.value }),
    success (data) {
      if (data[0]) {
        const supplier = data[0]
        stockIn.data.supplier.id = supplier.id
        stockIn.data.supplier.name = supplier.name
        stockIn.data.supplier.cellphone = supplier.cellphone
      } else {
        stockIn.data.supplier.id = ''
        stockIn.data.supplier.name = form.data.supplier.name
        stockIn.data.supplier.cellphone = form.data.supplier.contactCellphone
      }
    },
    lazy: true
  }
)
const supplierData = reactive(
  {
    id: '',
    name: '',
    cellphone: ''
  }
)
const supplierDataInit = () => {
  supplierData.id = ''
  supplierData.name = ''
  supplierData.cellphone = ''
}
const supplierMoal = ref(false)

const supplierMoalOpenClick = () => {
  supplierDataInit()
  supplierMoal.value = true
}
const supplierMoalOkClick = () => {
  if (!supplierData.name) return message.warning('请输选择或者输入供应商名称')
  if (!supplierData.cellphone) return message.warning('请输入供应商名称手机号')
  stockIn.data.supplier = { ...supplierData }
  supplierMoal.value = false
}
const supplierChange = ({ option = {} as any }) => {
  supplierData.id = option.id
  supplierData.cellphone = option.cellphone
}

const servicePriceUpdate = (item: any) => {
  // 维修价格计算
  let servicePrice = null
  if (item.enocloudGoods) {
    const specifications = item.enocloudGoods.specifications
    const goods = specifications.find((inner: any) => inner.id === item.purchaseGoods.enocloudGoodsSpecification.id)
    if (goods && goods.goodsSpecificationAddition) servicePrice = goods.goodsSpecificationAddition.servicePrice
  }
  if (servicePrice) {
    item.servicePrice = servicePrice
  } else {
    let isMatch = false
    rangeData.value.forEach(
      (inner) => {
        if (item.price >= inner.initialAmount && item.price < inner.endAmount) {
          item.servicePrice =
            inner.benefitType.code === 'F' ? add(item.price, inner.amount) : multiply(item.price, 1 + inner.amount)
          isMatch = true
        }
      }
    )
    if (!isMatch) {
      item.servicePrice = 0
    }
  }
}
const deleteGoodsClick = (index: any) => {
  stockIn.data.filterGoods.splice(index, 1)
}

const addGoodsclick = () => {
  addGoodsDrawer.form.init()
  addGoodsDrawer.visible = true
}

const addGoodsDrawer = reactive(
  {
    visible: false,
    form: {
      data: {
        specifications: [] as any[],
        specification: {
          name: '',
          id: ''
        },
        goods: {
          id: '',
          serialNo: '',
          oem: '',
          name: ''
        },
        price: 0,
        count: 0,
        servicePrice: 0,
        warehouseShelf: ''
      },
      init () {
        addGoodsDrawer.form.data.goods = {
          id: '',
          serialNo: '',
          oem: '',
          name: ''
        }
        addGoodsDrawer.form.data.specification = {
          id: '',
          name: ''
        }
        addGoodsDrawer.form.data.specifications = []
        addGoodsDrawer.form.data.price = 0
        addGoodsDrawer.form.data.count = 0
        addGoodsDrawer.form.data.servicePrice = 0
        addGoodsDrawer.form.data.warehouseShelf = ''
      }
    },
    confirmClick () {
      const goodsData = addGoodsDrawer.form.data
      if (!goodsData.goods.name) {
        return message.warning('配件不能为空!')
      }
      if (!goodsData.specification.name) {
        return message.warning('配件规格不能为空!')
      }
      if (!goodsData.count) {
        return message.warning('入库数量不能为0!')
      }
      const goods = {
        id: stockIn.data.filterGoods.length,
        count: goodsData.count,
        maxCount: goodsData.count,
        stockedInCount: 0,
        price: goodsData.price,
        servicePrice: goodsData.servicePrice,
        warehouseShelf: goodsData.warehouseShelf,
        oem: '',
        purchaseGoods: {
          status: {
            code: 'C'
          },
          enocloudGoodsSpecification: {
            ...goodsData.specification,
            goods: goodsData.goods
          }
        },
        // enocloudGoods: goodsData.goods.id
        //   ? null
        //   : {
        //       specifications: goodsData.specifications,
        //       ...goodsData.goods
        //     },
        // 入库是添加的商品可以删除，作为判断是可以删除的标识
        manuallyAdd: true
      }
      servicePriceUpdate(goods)
      stockIn.data.filterGoods.push(goods)
      addGoodsDrawer.visible = false
    }
  }
)

const goodsChange = ({ option = {} as any }) => {
  //  不是系统里选择的配件,没有id，后端不接受空字符串
  addGoodsDrawer.form.data.goods.id = option.id || null
  if (option.id) {
    addGoodsDrawer.form.data.goods.serialNo = option.serialNo
    addGoodsDrawer.form.data.goods.oem = option.oem
    addGoodsDrawer.form.data.specifications = option.specifications
    addGoodsDrawer.form.data.specification = option.specifications[0]
  } else {
    addGoodsDrawer.form.data.goods.serialNo = ''
    addGoodsDrawer.form.data.goods.oem = ''
    addGoodsDrawer.form.data.specifications = []
  }
}
const specificationChange = () => {
  const specification = addGoodsDrawer.form.data.specifications.find(
    (inner: any) => inner.id === addGoodsDrawer.form.data.specification.id
  )
  addGoodsDrawer.form.data.specification.name = specification ? specification.name : null
}

const imgPreview = reactive(
  {
    visible: false,
    imgUrl: '',
    click (url: string) {
      imgPreview.imgUrl = url
      imgPreview.visible = true
    }
  }
)
form.get()

const goodsSummary = computed(
  () => {
    const data = stockIn.data.stockInGoods
    let count = 0
    let totalAmount = 0
    const size = uniq(data.map((item: any) => item.id)).length || 0
    data.forEach(
      (item: any) => {
        item.amount = multiply(item.price, item.count)
        count += item.count
        totalAmount = add(item.amount, totalAmount)
      }
    )
    return {
      count,
      size,
      totalAmount
    }
  }
)

const address = computed(
  () => {
    const { addressArea, addressDetail } = form.data.inquiry
    if (addressArea)
      return addressArea.parent && addressArea.parent.parent
        ? `${addressArea.parent.parent.name}/${addressArea.parent.name}/${addressArea.name} ${addressDetail}`
        : `${addressArea.parent.name}/${addressArea.name} ${addressDetail}`
    return ''
  }
)
const isShowTolibrary = computed(
  () => {
    return form.data.purchaseGoods.filter((item: any) => ['C', 'A'].includes(item.status.code)).length
  }
)
</script>
<style scoped>
.cancel-icon {
  position: absolute;
  right: 100px;
  bottom: 15px;
  display: block;
  width: 145px;
  height: 145px;
}
/* .price-block {
  display: inline-block;
  max-width: 200px;
  min-width: 80px;
  margin: 0 8px;
} */
.switch-block__input {
  display: none;
}
.switch-block:hover .switch-block__text {
  display: none;
}
.switch-block:hover .switch-block__input {
  display: inline-flex;
}
</style>
